<!-- eslint-disable -->
<template>
  <div>
    <div
      v-if="this.userRole === 'admin'"
    >
      <client-tabs
        :user-role="userRole"
      />
    </div>
    <div
      v-else
    >
      <h1>Home Safety Checklist</h1>
      <div class="page-subtitle">If a client is to stay at home in a safe environment there are many things to do to make it that way. Use the following checklist to make the home a safe place to live.</div>
    </div>

    <b-tabs
        v-model="tabIndex"
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <b-tab>
          <template #title>
            <img
              src="@/assets/images/icons/checklist-icons/throughout-icon.svg"
              class="checklist-icons"  
            > 
            <span>Throughout the home</span>
          </template>

          <div
            class="float-left"
          >
            <img
              src="@/assets/images/icons/checklist-icons/throughout-icon.svg"
              class="content-checklist-icons text-center icon-container mr-1"  
            >
          </div>
          <div>
            <span class="content-title">Throughout the home</span><br>
            <span class="content-subtitle">The following checklist shows the safety throughout the home.</span>
          </div>
          
          <div class="card mt-2 p-0">
            <div class="card-body p-0">
              <b-list-group flush class="rounded">
                <b-list-group-item
                  v-for="option in homeOptions"
                  :key="option.value"
                  class="d-flex py-1 border-bottom safety-checklist"
                  :disabled="userRole === 'client'"
                >
                  <b-form-checkbox
                    v-model="home"
                    :value="option.value"
                    :class="(home[option.value-1] !== null) ? 'custom-control-success' : 'custom-control-danger custom-switch2'"
                    :disabled="userRole === 'client'"
                    switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right switch-x-icon">
                    <feather-icon icon="XIcon" />
                  </span>
                  </b-form-checkbox>
                    {{ option.text }}
                </b-list-group-item>
              </b-list-group>
            </div>

            <div
              class="btn-spacing"
              v-if="this.userRole !== 'client'"
            >
              <b-button
                variant="secondary"
                type="button"
                @click.prevent="addChecklistData()"
              >
                Save
              </b-button>

              <b-button
                variant="outline-dark"
                type="button"
                class="ml-1"
                @click.prevent="resetChecklistData('home')"
              >
                Reset
              </b-button>

              <b-button
                variant="secondary"
                class="float-right"
                @click="tabIndex++"
              >
                <span class="align-middle">Next</span>

                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </div>
          </div>

        </b-tab>

        <b-tab>
          <template #title>
            <img
              src="@/assets/images/icons/checklist-icons/kitchen-icon.svg"
              class="checklist-icons"  
            > 
            <span>In the kitchen</span>
          </template>

          <div
            class="float-left"
          >
            <img
              src="@/assets/images/icons/checklist-icons/kitchen-icon.svg"
              class="content-checklist-icons text-center icon-container mr-1"  
            >
          </div>
          <div>
            <span class="content-title">In the kitchen</span><br>
            <span class="content-subtitle">The following checklist shows the safety in the kitchen</span>
          </div>

          <div class="card mt-2 p-0">
            <div class="card-body p-0">
              <!-- <b-form-checkbox
                v-for="option in kitchenOptions"
                :key="option.value"
                v-model="kitchen"
                :value="option.value"
                :disabled="userRole === 'admin'"
              >
                {{ option.text }}
              </b-form-checkbox> -->
              <b-list-group flush class="rounded">
                <b-list-group-item
                  v-for="option in kitchenOptions"
                  :key="option.value"
                  class="d-flex py-1 border-bottom safety-checklist"
                  :disabled="userRole === 'client'"
                >
                  <b-form-checkbox
                    v-model="kitchen"
                    :value="option.value"
                    :disabled="userRole === 'client'"
                    :class="(kitchen[option.value-1] !== null ) ? 'custom-control-success' : 'custom-control-danger custom-switch2'"
                     name="check-button"
                    switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right switch-x-icon">
                    <feather-icon icon="XIcon" />
                  </span>
                  </b-form-checkbox>
                    {{ option.text }}
                </b-list-group-item>
              </b-list-group>
            </div>

            <div
              class="btn-spacing"
              v-if="this.userRole !== 'client'"
            >
              <b-button
                variant="secondary"
                type="button"
                @click.prevent="addChecklistData()"
              >
                Save
              </b-button>

              <b-button
                variant="outline-dark"
                type="button"
                class="ml-1"
                @click.prevent="resetChecklistData('kitchen')"
              >
                Reset
              </b-button>

              <b-button
                variant="secondary"
                class="float-right"
                @click="tabIndex++"
              >
                <span class="align-middle">Next</span>

                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </div>
          </div>

        </b-tab>

        <b-tab>
          <template #title>
            <img
              src="@/assets/images/icons/checklist-icons/bedroom-icon.svg"
              class="checklist-icons"
            > 
            <span>In the bedroom</span>
          </template>

          <div
            class="float-left"
          >
            <img
              src="@/assets/images/icons/checklist-icons/bedroom-icon.svg"
              class="content-checklist-icons text-center icon-container mr-1"  
            >
          </div>
          <div>
            <span class="content-title">In the bedroom</span><br>
            <span class="content-subtitle">The following checklist shows the safety in the bedroom.</span>
          </div>

          <div class="card mt-2 p-0">
            <div class="card-body p-0">
              <!-- <b-form-checkbox
                v-for="option in bedroomOptions"
                :key="option.value"
                v-model="bedroom"
                :value="option.value"
                :disabled="userRole === 'admin'"
              >
                {{ option.text }}
              </b-form-checkbox> -->
              <b-list-group flush class="rounded">
                <b-list-group-item
                  v-for="option in bedroomOptions"
                  :key="option.value"
                  class="d-flex py-1 border-bottom safety-checklist"
                  :disabled="userRole === 'client'"
                >
                  <b-form-checkbox
                    v-model="bedroom"
                    :value="option.value"
                    :disabled="userRole === 'client'"
                    :class="(bedroom[option.value-1] !== null ) ? 'custom-control-success' : 'custom-control-danger custom-switch2'"
                    name="check-button"
                    switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right switch-x-icon">
                    <feather-icon icon="XIcon" />
                  </span>
                  </b-form-checkbox>
                    {{ option.text }}
                </b-list-group-item>
              </b-list-group>
            </div>

            <div
              class="btn-spacing"
              v-if="this.userRole !== 'client'"
            >
              <b-button
                variant="secondary"
                type="button"
                @click.prevent="addChecklistData()"
              >
                Save
              </b-button>

              <b-button
                variant="outline-dark"
                type="button"
                class="ml-1"
                @click.prevent="resetChecklistData('bedroom')"
              >
                Reset
              </b-button>

              <b-button
                variant="secondary"
                class="float-right"
                @click="tabIndex++"
              >
                <span class="align-middle">Next</span>

                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </div>
          </div>

        </b-tab>

        <b-tab>
          <template #title>
            <img
              src="@/assets/images/icons/checklist-icons/bathroom-icon.svg"
              class="checklist-icons"  
            > 
            <span>In the bathroom</span>
          </template>

          <div
            class="float-left"
          >
            <img
              src="@/assets/images/icons/checklist-icons/bathroom-icon.svg"
              class="content-checklist-icons text-center icon-container mr-1"  
            >
          </div>
          <div>
            <span class="content-title">In the bathroom</span><br>
            <span class="content-subtitle">The following checklist shows the safety in the bathroom.</span>
          </div>

          <div class="card mt-2 p-0">
            <div class="card-body p-0">
              <!-- <b-form-checkbox
                v-for="option in bathroomOptions"
                :key="option.value"
                v-model="bathroom"
                :value="option.value"
                :disabled="userRole === 'admin'"
              >
                {{ option.text }}
              </b-form-checkbox> -->
              <b-list-group flush class="rounded">
                <b-list-group-item
                  v-for="option in bathroomOptions"
                  :key="option.value"
                  class="d-flex py-1 border-bottom safety-checklist"
                  :disabled="userRole === 'client'"
                >
                  <b-form-checkbox
                    v-model="bathroom"
                    :value="option.value"
                    :disabled="userRole === 'client'"
                    :class="(bathroom[option.value-1] !== null ) ? 'custom-control-success' : 'custom-control-danger custom-switch2'"
                    name="check-button"
                    switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right switch-x-icon">
                    <feather-icon icon="XIcon" />
                  </span>
                  </b-form-checkbox>
                    {{ option.text }}
                </b-list-group-item>
              </b-list-group>
            </div>

            <div
              class="btn-spacing"
              v-if="this.userRole !== 'client'"
            >
              <b-button
                variant="secondary"
                type="button"
                @click.prevent="addChecklistData()"
              >
                Save
              </b-button>

              <b-button
                variant="outline-dark"
                type="button"
                class="ml-1"
                @click.prevent="resetChecklistData('bathroom')"
              >
                Reset
              </b-button>

              <b-button
                variant="secondary"
                class="float-right"
                @click="tabIndex++"
              >
                <span class="align-middle">Next</span>

                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </div>
          </div>

        </b-tab>

        <b-tab>
          <template #title>
            <img
              src="@/assets/images/icons/checklist-icons/exterior-icon.svg"
              class="checklist-icons"  
            > 
            <span>Home exterior</span>
          </template>

          <div
            class="float-left"
          >
            <img
              src="@/assets/images/icons/checklist-icons/exterior-icon.svg"
              class="content-checklist-icons text-center icon-container mr-1"  
            >
          </div>
          <div>
            <span class="content-title">Home exterior</span><br>
            <span class="content-subtitle">The following checklist shows the safety of home exterior.</span>
          </div>

          <div class="card mt-2 p-0">
            <div class="card-body p-0">
              <!-- <b-form-checkbox
                v-for="option in exteriorOptions"
                :key="option.value"
                v-model="exterior"
                :value="option.value"
                :disabled="userRole === 'admin'"
              >
                {{ option.text }}
              </b-form-checkbox> -->
              <b-list-group flush class="rounded">
                <b-list-group-item
                  v-for="option in exteriorOptions"
                  :key="option.value"
                  class="d-flex py-1 border-bottom safety-checklist"
                  :disabled="userRole === 'client'"
                >
                  <b-form-checkbox
                    v-model="exterior"
                    :value="option.value"
                    :disabled="userRole === 'client'"
                    :class="(exterior[option.value-1] !== null ) ? 'custom-control-success' : 'custom-control-danger custom-switch2'"
                    name="check-button"
                    switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right switch-x-icon">
                    <feather-icon icon="XIcon" />
                  </span>
                  </b-form-checkbox>
                    {{ option.text }}
                </b-list-group-item>
              </b-list-group>
            </div>

            <div
              class="btn-spacing"
              v-if="this.userRole !== 'client'"
            >
              <b-button
                variant="secondary"
                type="button"
                @click.prevent="addChecklistData()"
              >
                Save
              </b-button>

              <b-button
                variant="outline-dark"
                type="button"
                class="ml-1"
                @click.prevent="resetChecklistData('exterior')"
              >
                Reset
              </b-button>
            </div>
          </div>

        </b-tab>
      </b-tabs>

    <b-row class="d-flex flex-row pl-0 pt-2">
      <b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BTabs, BTab, BFormCheckbox, BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import ClientTabs from './ClientTabs.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BFormCheckbox,
    BButton,
    BListGroup,
    BListGroupItem,

    ClientTabs,
  },
  data() {
    return {
      clientId: this.$route.query.id,
      userRole: '',
      home: [],
      kitchen: [],
      bedroom: [],
      bathroom: [],
      exterior: [],
      tabIndex: 0,
      tempFetchData: [],
      homeOptions: [
        {
          value: '1',
          text: 'Electric cords are properly plugged in and safety tucked away',
        },
        {
          value: '2',
          text: 'Extension cords aren’t overloaded',
        },
        {
          value: '3',
          text: 'Smoke and carbon monoxide detectors are present and have fresh batteries',
        },
        {
          value: '4',
          text: 'Electrical outlets aren’t warm to the touch',
        },
        {
          value: '5',
          text: 'The home is well-lit – inside and outside',
        },
        {
          value: '6',
          text: 'Night lights are present in hallways, stairwells, bedrooms, and bathrooms',
        },
        {
          value: '7',
          text: 'Electric heaters are placed away from curtains, rugs, and fumishings',
        },
        {
          value: '8',
          text: 'Electric appliances are a safe distance from water',
        },
        {
          value: '9',
          text: 'Fireplace chimneys are clear of accumulation and checked yearly',
        },
        {
          value: '10',
          text: 'Light switches are present at the top and bottom of stairs',
        },
        {
          value: '11',
          text: 'Light switches are located near room entrances',
        },
        {
          value: '12',
          text: 'Stairwells are well-lit',
        },
        {
          value: '13',
          text: 'Stairwells are free of objects',
        },
        {
          value: '14',
          text: 'Stair handrails are present and sturdy',
        },
        {
          value: '15',
          text: 'Stairs are marked for visibility with contrasting tape',
        },
        {
          value: '16',
          text: 'Steps are even and uniform in size and height',
        },
        {
          value: '17',
          text: 'Floors aren’t slippery or highly polished',
        },
        {
          value: '18',
          text: 'Carpeting, linoleum and plastic stair treads are secure',
        },
        {
          value: '19',
          text: 'Carpets do not have holes and snags',
        },
        {
          value: '20',
          text: 'Carpet edges are securely fastened',
        },
        {
          value: '21',
          text: 'Water temperature is reduced to prevent scalding',
        },
        {
          value: '22',
          text: 'Water faucets are clearly marked hot and cold',
        },
        {
          value: '23',
          text: 'House smoking rules are established',
        },
        {
          value: '24',
          text: 'Rope ladders are available on upper levels',
        },
        {
          value: '25',
          text: 'Furnace is checked yearly',
        },
        {
          value: '26',
          text: 'Room furniture patterns permit easy access to doors and windows',
        },
        {
          value: '27',
          text: 'Rooms are free of floor clutter',
        },
        {
          value: '28',
          text: 'Stairs and pathways are free of objects',
        },
        {
          value: '29',
          text: 'Drawers, doors and windows open and shut easily',
        },
        {
          value: '30',
          text: 'Flashlights are available in every room',
        },
        {
          value: '31',
          text: 'Glow tape is stuck on key items to identify them in the dark',
        },
        {
          value: '32',
          text: 'Cleaners and poisons are clearly marked',
        },
        {
          value: '33',
          text: 'Step stools are sturdy',
        },
        {
          value: '34',
          text: 'Window and door locks are secure and operating',
        },
        {
          value: '35',
          text: 'Medications are properly stored and usage instructions are written down',
        },
        {
          value: '36',
          text: 'A first aid kit is available and contains up-to-date supplies',
        },
      ],
      kitchenOptions: [
        {
          value: '1',
          text: 'Dishes and food are stored on lower shelves',
        },
        {
          value: '2',
          text: 'Towels and curtains are kept away from the stove',
        },
        {
          value: '3',
          text: 'Lighting is sufficient over stove, sink and countertops',
        },
        {
          value: '4',
          text: 'Radio and electric appliances are a safe distance from the sink',
        },
        {
          value: '5',
          text: '“Off” indicators on stove and appliances are clearly marked with brightly colored tape',
        },
        {
          value: '6',
          text: 'A telephone is in the kitchen',
        },
        {
          value: '7',
          text: 'Emergency telephone numbers are displayed near the telephone and on the refrigerator',
        },
        {
          value: '8',
          text: 'A fire extinguisher is in easy reach and in working order',
        },
        {
          value: '9',
          text: 'Whistling teakettles and food timers are in use',
        },
        {
          value: '10',
          text: 'Food is properly stored in the freezer',
        },
        {
          value: '11',
          text: 'No expired food is in the refrigerator or cupboards',
        },
        {
          value: '12',
          text: 'Plastic, easy-open containers and dishes replace glassware',
        },
        {
          value: '13',
          text: 'Heavy pots or pans are replaced with lighter ones',
        },
        {
          value: '14',
          text: 'Pot holder mitts are available and uses',
        },
        {
          value: '15',
          text: 'Refrigerator and stove are in good working order',
        },
        {
          value: '16',
          text: 'Sturdy step stools are available',
        },
        {
          value: '17',
          text: 'Pet dishes are tucked away from the walking path',
        },
      ],
      bedroomOptions: [
        {
          value: '1',
          text: 'Lamps and light switches are within reach of bed',
        },
        {
          value: '2',
          text: 'The electric blanket is in good working order',
        },
        {
          value: '3',
          text: 'The telephone is accessible from the bed',
        },
        {
          value: '4',
          text: 'An emergency telephone list is near the telephone',
        },
        {
          value: '5',
          text: 'A flashlight and whistle are near the bed',
        },
        {
          value: '6',
          text: 'Medications are stored away from the nightstand',
        },
        {
          value: '7',
          text: 'The bed is an appropriate height',
        },
      ],
      bathroomOptions: [
        {
          value: '1',
          text: 'Non-skid decals and rubber mats are available for the tub and shower',
        },
        {
          value: '2',
          text: 'Floor rugs are secure and won’t skid',
        },
        {
          value: '3',
          text: 'Grab bars and handrails are next to the toilet, and in the tub and shower',
        },
        {
          value: '4',
          text: 'Handrails are secure',
        },
        {
          value: '5',
          text: 'Shower and tub stools are present',
        },
        {
          value: '6',
          text: 'There is a telephone access in the bathroom',
        },
      ],
      exteriorOptions: [
        {
          value: '1',
          text: 'Tools and yard equipment are safely and securely stored',
        },
        {
          value: '2',
          text: 'Solvents, paints and sprays are clearly marked',
        },
        {
          value: '3',
          text: 'Goggles are worn when using power equipment',
        },
        {
          value: '4',
          text: 'Stair rails are secure',
        },
        {
          value: '5',
          text: 'Walking paths are clear and safe, with no holes in concrete',
        },
        {
          value: '6',
          text: 'Leaves and snow are cleared away',
        },
        {
          value: '7',
          text: 'There is telephone access while outside',
        },
      ],
    }
  },
  created() {
    const user = JSON.parse(localStorage.getItem('userData'))
    this.userRole = user.role

    if (this.userRole !== 'admin') {
      this.clientId = user.clientId
    }

    this.fetchData()
  },
  methods: {
    fetchData() {
      const formdata = {
        clientId: this.clientId,
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/getChecklistDataAction', {
        param: formdata,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
        .then(response => {
          this.home = response.data.checklistData.home
          this.kitchen = response.data.checklistData.kitchen
          this.bedroom = response.data.checklistData.bedroom
          this.bathroom = response.data.checklistData.bathroom
          this.exterior = response.data.checklistData.exterior
          this.tempFetchData = response.data.checklistData
          if (this.home === false) {
            this.home = []
          }
          if (this.kitchen === false) {
            this.kitchen = []
          }
          if (this.bedroom === false) {
            this.bedroom = []
          }
          if (this.bathroom === false) {
            this.bathroom = []
          }
          if (this.exterior === false) {
            this.exterior = []
          }
          if (this.tempFetchData === false) {
            this.tempFetchData = []
          }
        }, response => {
        // Destroy session
          if (response.status !== 200) {
            this.$router.push({ name: 'login' })
          }
        })
        .catch(error => {
          console.log(error.response.status)
        })
    },
    addChecklistData() {
      const formData = {
        clientId: this.clientId,
        home: this.home,
        kitchen: this.kitchen,
        bedroom: this.bedroom,
        bathroom: this.bathroom,
        exterior: this.exterior,
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'client/addChecklistDataAction', {
        param: formData,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
        .then(response => {
          console.log(response)
          Swal.fire({
            icon: 'success',
            title: 'Saved Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    resetChecklistData(key) {
      if (key === 'home') {
        const data = this.tempFetchData.home
        this.home = data
      } else if (key === 'kitchen') {
        const data = this.tempFetchData.kitchen
        this.kitchen = data
      } else if (key === 'bedroom') {
        const data = this.tempFetchData.bedroom
        this.bedroom = data
      } else if (key === 'bathroom') {
        const data = this.tempFetchData.bathroom
        this.bathroom = data
      } else if (key === 'exterior') {
        const data = this.tempFetchData.exterior
        this.exterior = data
      }
    },
  },
}
</script>

<style scoped>

.list-group-item.disabled{
  color: #001C24;
}

.checklist-icons {
  width: 20px;
  margin-right: 8px;
}
.nav-pills .nav-link.active .checklist-icons {
  filter: brightness(0) invert(1);
}
.icon-container {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 2px;
}
.content-title {
  font-weight: 700;
  font-size: 18px;
}
.content-subtitle {
  color: #98A1AB;
}
.btn-spacing {
  padding: 20px;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #5e5873;
}
.page-subtitle {
  margin: 30px 0 15px 0;
  /* font-weight: 700; */
}
.safety-checklist{
  padding-left: 17.5px!important;
}
.custom-switch2 .custom-control-label::before {
    left: 0;
    background-color: #ea5455!important;
}
.custom-switch .custom-control-label::before {
    left: 0;
    background: #ea5455;
}
.switch-x-icon {
  color: white!important;
}
</style>
